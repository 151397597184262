import React from "react";
import { graphql } from "gatsby";

import SingleVendorPage from "vendors/SingleVendorPage";
import SEO from "components/SEO";

import { groupOpeningHours } from "utils";
import getImage from "utils/getImage";

const Facility = ({ data }) => {
  const { strapiFacility, allStrapiProperty } = data;

  const facility = {
    ...strapiFacility,
    team: strapiFacility.meet_the && {
      title: strapiFacility.meet_the.title,
      profiles:
        strapiFacility.meet_the?.profiles?.map((profile) => ({
          ...profile,
          description: profile.description.data.description,
          image: getImage(profile.image),
        })) ?? [],
    },
    openingHours: groupOpeningHours(strapiFacility.openingHours),
    contactInfo: {
      ...strapiFacility.contactInfo,
      city: strapiFacility.contactInfo?.address?.city ?? "",
    },
    gallery: strapiFacility.gallery
      .map((node) => {
        if (node?.video) {
          return {
            url: node.video[0].localFile?.publicURL,
            type: "video",
          };
        }
        return {
          image: getImage(node?.image),
          type: "image",
        };
      })
      .filter((media) => media.url || media.image)
      .sort((media1, media2) => {
        if (media1.type === "video") {
          return media2.type === "video" ? 0 : -1;
        }
        if (media2.type === "video") {
          return 1;
        }
        return 0;
      }),

    hero_image: getImage(strapiFacility.hero_image),
    property: allStrapiProperty,
  };

  return <SingleVendorPage vendor={facility} relatedVendors={[]} />;
};

export const query = graphql`
  query FacilityPageQuery($slug: String, $propertyId: [Int]) {
    strapiFacility(slug: { eq: $slug }) {
      slug
      name
      description
      contactInfo: contact_info {
        ...contactInfoFragment
      }
      openingHours: opening_hours {
        ...openingHoursFragment
      }
      gallery {
        ...galleryFragment
      }
      hero_image {
        ...strapiMediaFragment
      }
      meet_the {
        ...meetTheTeamFragment
      }
      information_sections {
        ...informationSectionsFragment
      }
    }

    allStrapiProperty(filter: { strapi_id: { in: $propertyId } }) {
      nodes {
        slug
        code
        strapi_id
      }
    }
  }
`;

export const Head = ({ data }) => (
  <SEO title={data?.strapiFacility?.name || "Facility"} />
);

export default Facility;
